<template>
  <sign-page
    title-text="采购结果"
    :request="request"
    :column-list="columnList"
    table-size="small"
    @dataFileUpdate="dataFileUpdate"
    :title-menus="[{key: 'add', label: '新增'}]"
    :onFormChange="onFormChange"
    :table-actions="tableActions"
    :form-parms-add="formParmsAdd"
    :initAddFormData="initAddFormData"
    :form-parms-update="formParmsUpdate"
    :table-actions-fixed="true"
    :need-data-file="true">
  </sign-page>
</template>

<script>
import {
  purchaseRequest as request,
  purchaseMethodBatchDetailsRequest
} from '../../api'

import GoodsSelect from '../base/assets_type/goodsSelect'

import {
  dateOperating
} from '@/fmlib'

export default {
  computed: {
    tableActions () {
      let data = [{
        key: 'data_file',
        label: '扫描件'
      },
      {
        key: 'edit',
        label: '修改'
      },
      {
        key: 'del',
        label: '删除'
      }]
      return data
    },
    formParmsAdd: {
      get () {
        let data = [{
          type: 'cmp',
          label: '采购商品',
          cmp: GoodsSelect,
          key: 'goodsId',
          check: {
            required: true
          }
        },
        {
          type: 'multipleChoose',
          label: '关联采购方式',
          selectDatas: this.purchaseList,
          key: 'purchaseMethodBatchDetailIds'
        },
        {
          type: 'input',
          label: '品牌',
          key: 'pp'
        },
        {
          type: 'input',
          label: '规格型号',
          key: 'ggxh'
        },
        {
          type: 'input',
          label: '单价',
          key: 'price',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '数量',
          key: 'num',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'input',
          label: '总价',
          key: 'total',
          check: {
            required: true,
            rules: {
              type: 'number'
            }
          }
        },
        {
          type: 'datePicker',
          label: '采购时间',
          key: 'endTime'
        },
        {
          type: 'select',
          label: '采购负责人',
          key: 'userId',
          selectDatas: this.$store.getters.userList,
          check: {
            required: true
          }
        },
        {
          type: 'select',
          label: '创建部门',
          key: 'orgId',
          selectDatas: this.$store.getters.orgList,
          fmDisabled: !this.$authFunsProxy.get,
          check: {
            required: true
          }
        }]
        return data
      }
    },
    currentOrgId () {
      return this.$store.getters.currentOrgId
    },
    formParmsUpdate: {
      get () {
        let data = []
        this.formParmsAdd.forEach((item) => {
          let newItem = {}
          for (let key in item) {
            newItem[key] = item[key]
          }
          data.push(newItem)
        })
        return data
      }
    },
    columnList: {
      get () {
        let data = [{
          title: '编码',
          field: 'code',
          sort: true
        },
        {
          title: '商品名称',
          field: 'goodsName',
          sort: true
        },
        {
          title: '品牌',
          field: 'pp',
          sort: true
        },
        {
          title: '规格型号',
          field: 'ggxh',
          sort: true
        },
        {
          title: '单价',
          dataType: Number,
          field: 'price',
          sort: true
        },
        {
          title: '数量',
          dataType: Number,
          field: 'num',
          sort: true
        },
        {
          title: '总价',
          dataType: Number,
          field: 'total',
          sort: true
        },
        {
          title: '采购时间',
          field: 'endTime',
          dataType: Date,
          sort: true,
          render: (h, rowData) => {
            return h('div', rowData && rowData.endTime ? rowData.endTime.slice(0, 10) : '-')
          }
        },
        {
          title: '采购负责人',
          field: 'userName',
          sort: true
        },
        {
          title: '创建部门',
          field: 'orgName',
          sort: true
        }]
        return data
      }
    }
  },
  created () {
    if (this.$authFunsProxy.add || this.$authFunsProxy.update) {
      this.$store.dispatch('loadUserList')
      this.$store.dispatch('loadGoodsList')
      this.$store.dispatch('loadOrgList')
      this.loadPurchase()
    }
  },
  methods: {
    initAddFormData () {
      if (!this.$authFunsProxy.getMy && !this.currentOrgId) {
        this.$notify({
          title: '系统提示',
          message: '无当前组织架构，无法新建',
          type: 'info'
        })
        throw Error()
      }
      return {
        orgId: this.currentOrgId
      }
    },
    onFormChange (key, value, formData) {
      if (['price', 'num'].includes(key)) {
        if (formData.price && formData.num && !isNaN(formData.price) && !isNaN(formData.num)) {
          return {
            total: Number((Number(formData.price) *  Number(formData.num)).toFixed(2))
          }
        }
      }
    },
    async loadData () {
      let data = []
      if (this.$authFunsProxy.get) {
        data = await request.get()
      } else if (this.$authFunsProxy.getMyOrg) {
        data = await request.getMyOrg()
      }
      data.forEach(v => v.purchaseMethodBatchDetailIds = v.purchaseMethodBatchDetailIds ? v.purchaseMethodBatchDetailIds.split(',').map(v =>  Number(v)) : [])
      return data
    },
    async loadPurchase () {
      let data = await purchaseMethodBatchDetailsRequest.get({
        status: 'end'
      })
      this.purchaseList = data.map(v => {
        let label = v.code || ''
        if (v.purchaseBatchDetailList && v.purchaseBatchDetailList.length > 0) {
          label = label + ' '
          v.purchaseBatchDetailList.forEach(v1 => {
            label += (v1.goodsName || '') + ' ' + (v1.orgName || '') + ' ' + (v1.total || '') + '元' + ' '
          })
        }
        return {
          key: v.id,
          label
        }
      })
    },
    async dataFileUpdate (parm, pageVm) {
      await request.update(pageVm.chooseData.id, {
        fileIds: parm && parm.length > 0 ? parm.join(',') : null,
        imgUploadTime: parm && parm.length > 0 ? dateOperating.computeDay({days: 0, date: new Date(), format: 'yy-mm-dd hh:mm:ss'}) : null
      })
      pageVm.loadData()
    }
  },
  data () {
    return {
      request: Object.assign({}, request, {
        get: this.loadData
      }),
      pageVm: null,
      purchaseList: []
    }
  }
}
</script>

<style lang="less" scoped>
.img-c {
  height: 60vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  .no-img {
    font-size: 1.4rem;
    color: #999;
  }
  img {
    max-height: 100%;
    max-width: 100%;
  }
}
.file-d-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.file-d-footer-wx {
  display: none;
  justify-content: center;
  align-items: center;
}
</style>